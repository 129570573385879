<template>
  <div class="classdetail">
    分类详情
  </div>
</template>

<script>
export default {
  components: {},
  name: '',
  data () {
    return {
    }
  },
  methods: {}
}
</script>

<style scoped lang='less'>

</style>